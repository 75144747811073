<template>
  <Layout>
    <el-form
      ref="form"
      :inline="true"
      :model="formData"
    >
      <el-form-item>
        <!-- <el-select
          v-model="formData.app_key"
          placeholder="选择APP"
          collapse-tags
          clearable
        >
          <el-option
            v-for="(item, index) in appKeysList"
            :key="index"
            :label="item.name"
            :value="item.appKey"
          />
        </el-select> -->
        <el-select
          v-model="formData.app_key"
          placeholder="选择APP"
          collapse-tags
          clearable
        >
          <el-option
            v-for="(item, index) in appKeysList"
            :key="index"
            :label="item.name"
            :value="item.app_key"
          />
          <el-option
            label="其他"
            value="other"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="name">
        <el-input
          v-model="formData.name"
          placeholder="订阅名称"
          clearable
        />
      </el-form-item>
      <el-form-item prop="goods_id">
        <el-input
          v-model="formData.goods_id"
          placeholder="商品ID"
          clearable
        />
      </el-form-item>

      <el-form-item>
        <div>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
          <el-button
            @click="EditItem()"
          >
            创建
          </el-button>
        </div>
      </el-form-item>
      <!-- </el-form-item> -->
    </el-form>
    <!-- table -->
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="排序"
        prop="msorts"
      />
      <el-table-column
        label="订阅名称"
        prop="name"
      />
      <el-table-column
        label="商品ID"
        prop="goods_id"
      />
      <el-table-column label="价格(usd)">
        <template #default="{row}">
          <span>{{ row.usd_price }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="结算周期"
        prop="time_number"
      >
        <template #default="{row}">
          <span>{{ row.time_number }}</span>
          <!-- 1.day 2.month 3.year -->
          <span v-if="row.time_type == 1">天</span>
          <span v-if="row.time_type == 2">个月</span>
          <span v-if="row.time_type == 3">年</span>
        </template>
      </el-table-column>
      <el-table-column
        label="时长限制"
      >
        <template #default="{row}">
          <!-- <span v-if="row.subscription_time_type === 1">每天</span>
          <span v-if="row.subscription_time_type === 2">每月</span> -->
          <span v-if="row.subscription_time>0">{{ row.subscription_time / 60 / 60 }}小时</span>
        </template>
      </el-table-column>
      <el-table-column
        label="上次更新时间"
        prop="update_time"
        :formatter="(row, column, cellValue) => utcFormatter(cellValue)"
      />
      <el-table-column label="状态">
        <template #default="{row}">
          <span v-if="row.status === 1"> <el-tag
            size="small"
            type="success"
          >已上线</el-tag> </span>
          <span v-else> <el-tag
            size="small"
            type="danger"
          >未上线</el-tag> </span>
        </template>
      </el-table-column>
      <el-table-column
        label="关联第三方"
      >
        <template #default="{row}">
          <el-button
            type="primary"
            @click="relate(row.goods_id)"
          >
            关联
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="{row}">
          <el-button
            :loading="row.loading"
            @click="EditItem(row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.page"
      :limit.sync="formData.pageSize"
      @pagination="getList"
    />
    <el-dialog
      :title="subFormData.goods_id ? '编辑商品' : '添加商品'"
      :visible.sync="dialogVisible"
      width="1000px"
      :before-close="handleClose"
    >
      <el-form
        ref="subFormData"
        :model="subFormData"
        label-width="210px"
        size="small"
        label-position="right"
        :rules="rules"
        @submit.native.prevent
      >
        <el-form-item
          label="订阅名称:"
          prop="name"
        >
          <el-input
            v-model="subFormData.name"
            placeholder="订阅名称"
          />
        </el-form-item>
        <el-form-item
          prop="app_key"
          label="选择APP:"
        >
          <!-- <el-select
            v-model="subFormData.app_key"
            placeholder="选择APP"
            :disabled="!!subFormData.goods_id"
          >
            <el-option
              v-for="(item, index) in appKeysList"
              :key="index"
              :label="item.name"
              :value="item.appKey"
            />
          </el-select> -->
          <el-select
            v-model="subFormData.app_key"
            placeholder="选择APP"
            :disabled="!!subFormData.goods_id"
            collapse-tags
            clearable
          >
            <el-option
              v-for="(item, index) in appKeysList"
              :key="index"
              :label="item.name"
              :value="item.app_key"
            />
            <el-option
              label="其他"
              value="other"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="价格:"
          prop="usd_price"
        >
          <el-input
            v-model.trim="subFormData.usd_price"
            placeholder="价格"
          />
        </el-form-item>
        <el-form-item
          label="时长限制(h):"
          prop="subscription_time"
        >
          <el-input
            v-model.trim="subFormData.subscription_time_copy"
            placeholder="时长限制"
            @change="changeSubscriptionTime"
          />
        </el-form-item>
        <el-form-item
          label="结算周期:"
          prop="time_number"
        >
          <!-- <el-input
            v-model.trim="subFormData.time_number"
          /> -->
          <el-input
            v-model="subFormData.time_number"
            :disabled="!!subFormData.goods_id"
            placeholder="请输入内容"
          >
            <el-select
              slot="append"
              v-model="subFormData.time_type"
              placeholder="请选择"
              :disabled="!!subFormData.goods_id"
              style="width:60px"
            >
              <el-option
                label="天"
                :value="1"
              />
              <el-option
                label="月"
                :value="2"
              />
              <el-option
                label="年"
                :value="3"
              />
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item
          label="谷歌ID:"
          prop="google_product_id"
        >
          <el-input
            v-model.trim="subFormData.google_product_id"
          />
        </el-form-item>
        <el-form-item
          label="苹果ID:"
          prop="apple_product_id"
        >
          <el-input
            v-model.trim="subFormData.apple_product_id"
          />
        </el-form-item>
        <!-- 添加角标 -->
        <el-form-item
          label="角标:"
        >
          <UploadImage
            :limit="1"
            class="image"
            :file-list.sync="imageList"
          />
        </el-form-item>
        <div class="cornerMark">
          <el-form-item
            label="角标文案:"
            prop="corner_text"
          >
            <el-input
              v-model.trim="subFormData.corner_text"
              class="corner_text"
              placeholder=""
            />
          </el-form-item>
          <el-form-item
            label="底色:"
            prop="msorts"
            label-width="60px"
          >
            <el-input
              v-model.trim="subFormData.corner_text_style.background_color"
              class="background_color"
              placeholder=""
            />
          </el-form-item>
          <el-form-item
            label="文字色:"
            prop="msorts"
            label-width="70px"
          >
            <el-input
              v-model.trim="subFormData.corner_text_style.color"
              class="color"
              placeholder=""
            />
          </el-form-item>
        </div>
        <el-form-item
          label="排序:"
          prop="msorts"
        >
          <el-input
            v-model.trim="subFormData.msorts"
            placeholder=""
          />
        </el-form-item>
        <el-form-item
          label="商品描述:"
          prop="content"
        >
          <el-input
            v-model="subFormData.content"
            type="textarea"
          />
        </el-form-item>

        <el-form-item
          label="商品描述（富文本）:"
          prop="content_html"
          style="padding-right: 10px;"
        >
          <Tinymce
            v-if="dialogVisible"
            ref="tinymceEditor"
            v-model="subFormData.content_html"
            :height="300"
          />
        </el-form-item>
        <el-form-item
          label="是否首充优惠:"
        >
          <el-radio-group
            v-model="subFormData.first_recharge_offer"
            @change="agreeChange()"
          >
            <el-radio :label="1">
              是
            </el-radio>
            <el-radio
              :label="0"
            >
              否
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 确定优惠显示 -->
        <div v-if="subFormData.first_recharge_offer === 1">
          <el-form-item
            label="优惠角标:"
          >
            <UploadImage
              :limit="1"
              class="image"
              :file-list.sync="imageListOne"
            />
          </el-form-item>
          <div class="cornerMark">
            <el-form-item
              label="优惠角标文案:"
            >
              <el-input
                v-model.trim="subFormData.first_recharge_offer_corner_text "
                class="corner_text"
                placeholder=""
              />
            </el-form-item>
            <el-form-item
              label="底色:"
              prop="msorts"
              label-width="60px"
            >
              <el-input
                v-model.trim="subFormData.first_recharge_offer_corner_text_style.background_color"
                class="background_color"
                placeholder=""
              />
            </el-form-item>
            <el-form-item
              label="文字色:"
              prop="msorts"
              label-width="70px"
            >
              <el-input
                v-model.trim="subFormData.first_recharge_offer_corner_text_style.color"
                class="color"
                placeholder=""
              />
            </el-form-item>
          </div>
          <el-form-item
            label="优惠价格:"
          >
            <el-input
              v-model.trim="subFormData.first_recharge_offer_price"
              placeholder="优惠价格"
            />
          </el-form-item>
          <!-- :disabled="!!subFormData.goods_id" -->
          <el-form-item
            label="优惠有效期:"
          >
            <el-input
              v-model="subFormData.first_recharge_offer_time"

              placeholder="请输入内容"
            >
              <el-select
                slot="append"
                v-model="subFormData.first_recharge_offer_time_type"
                placeholder="请选择"
                style="width:60px"
              >
                <el-option
                  label="天"
                  :value="1"
                />
                <el-option
                  label="月"
                  :value="2"
                />
                <el-option
                  label="年"
                  :value="3"
                />
              </el-select>
            </el-input>
            （最短时限为3天）
          </el-form-item>
          <el-form-item
            label="优惠有效期文案:"
          >
            <el-input
              v-model="subFormData.first_recharge_offer_time_desc"
              placeholder="优惠有效期文案"
            />
          </el-form-item>
          <el-form-item
            label="优惠时长限制(h):"
          >
            <el-input
              v-model.trim="subFormData.first_recharge_offer_subscription_time"
              placeholder="时长限制"
            />
          </el-form-item>
          <el-form-item
            label="优惠状态商品描述:"
            prop="first_recharge_offer_content"
          >
            <el-input
              v-model="subFormData.first_recharge_offer_content"
              type="textarea"
            />
          </el-form-item>
          <el-form-item
            label="优惠状态商品描述（富文本）:"
            prop="first_recharge_offer_content_html"
            style="padding-right: 10px;"
          >
            <Tinymce
              v-if="dialogVisible"
              ref="tinymceEditorHtml"
              v-model="subFormData.first_recharge_offer_content_html"
              :height="300"
            />
          </el-form-item>
        </div>
        <el-form-item
          label="面向国家:"
        >
          <el-select
            v-model="subFormData.regions.region_type"
          >
            <el-option
              label="全部国家"
              value="all"
            />
            <el-option
              label="部分国家显示"
              value="display"
            />
            <el-option
              label="部分国家隐藏"
              value="hide"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="subFormData.regions.region_type !== 'all'"
          label="国家列表:"
        >
          <el-input
            v-model="subFormData.regions.region_code"
          />
        </el-form-item>
        <el-form-item
          label="状态:"
          prop="status"
        >
          <el-radio-group v-model="subFormData.status">
            <el-radio :label="1">
              上架
            </el-radio>
            <el-radio :label="2">
              下架
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose()">取 消</el-button>
        <el-button
          :loading="addLoading"
          type="primary"
          @click="addUserBlackAndWhiteList"
        >确 定</el-button>
      </span>
    </el-dialog>
  </Layout>
</template>

<script>
// import { getAppKeys } from '@/api/blacklist.js'
import { commonRequestGet } from '@/api/commonApi.js'
import {
  getGoods,
  createOpenGood,
  createGood,
  updateGood
} from '@/api/CommodityManagementInterfacelist.js'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import Tinymce from '@/components/Tinymce/index'

export default {
  components: {
    Tinymce
  },

  data () {
    return {
      appKeysList: [],
      formData: {
        goods_type: 2, // goods_type 1.时长卡商品 2.订阅商品
        app_key: '',
        page: 1,
        pageSize: 20,
        goods_id: '',
        name: ''

      },
      // listInfo: {
      //   loading: false,
      //   total: 0,
      //   priceTotal: 0,
      //   list: []
      // },
      listInfo: {
        list: [],
        total: 0,
        loading: false,
        yesterdayUserCount: 0
      },
      dialogVisible: false,
      imageList: [],
      imageListOne: [],
      subFormData: {
        goods_type: 2,
        app_key: '',
        apple_product_id: '',
        google_product_id: '',
        name: '',
        content: '',
        content_html: '',
        usd_price: '',
        subscription_time_type: 2,
        subscription_time: '',
        subscription_time_copy: '',
        time_type: 2,
        time_number: 1,
        msorts: 0,
        goods_id: '',
        corner_image: '', // 角标
        corner_text: '', // 角标文案
        corner_text_style: {
          color: '', // 文字色
          background_color: '' // 底色
        },
        first_recharge_offer: 0, // 是否首充优惠
        first_recharge_offer_corner_image: '', // 优惠角标图片
        first_recharge_offer_corner_text: '', // 优惠角标文案
        first_recharge_offer_price: 0, // 优惠价格
        first_recharge_offer_time: 0, // 优惠有效期
        first_recharge_offer_time_desc: '', // 优惠有效期文案
        first_recharge_offer_subscription_time: 0, // 优惠时长限制
        first_recharge_offer_time_type: 1, // 优惠时长单位
        first_recharge_offer_content: '', // 优惠状态商品描述
        first_recharge_offer_content_html: '',
        first_recharge_offer_corner_text_style: {
          background_color: '', // 底色
          color: '' // 文字色
        },
        regions: {
          region_type: 'all',
          region_code: ''
        },
        status: 2
      },
      addLoading: false,
      rules: {
        // app_key: [{ required: true, message: '必填', trigger: ['blur', 'change'] }],
        name: [{ required: true, message: '必填', trigger: ['blur'] }],
        usd_price: [{ required: true, message: '必填', trigger: ['blur'] }],
        subscription_time: [{ required: true, message: '必填', trigger: ['blur'] }],
        time_number: [{ required: true, message: '必填', trigger: ['blur'] }]
        // msorts: [{ required: true, message: '必填', trigger: ['blur', 'change'] }]
      }
    }
  },
  created () {
    // this.getList()
    this.getAppKeys()
    console.log(this.imageListOne[0]?.url, '----------------')
  },
  methods: {
    agreeChange () {
      // console.log('1111')
      if (this.subFormData.first_recharge_offer === 0) {
        this.subFormData.first_recharge_offer_corner_image = '' // 优惠角标图片
        this.subFormData.first_recharge_offer_corner_text = '' // 优惠角标文案
        this.subFormData.first_recharge_offer_price = 0 // 优惠价格
        this.subFormData.first_recharge_offer_time = 0 // 优惠有效期
        this.subFormData.first_recharge_offer_subscription_time = 0 // 优惠时长限制
        this.subFormData.first_recharge_offer_time_desc = '' // 优惠有效期文案
        this.subFormData.first_recharge_offer_content = '' // 优惠状态商品描述
        this.subFormData.first_recharge_offer_content_html = ''
        this.subFormData.first_recharge_offer_corner_text_style = {
          background_color: '', // 底色
          color: '' // 文字色
        }
      }
    },
    getList () {
      this.listInfo.loading = true
      getGoods(this.formData)
        .then(res => {
          this.listInfo.list = res.data
          this.listInfo.total = res.total
          this.listInfo.priceTotal = res.priceTotal
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    // getAppKeys () {
    //   getAppKeys({
    //     platform: ''
    //   }).then(res => {
    //     if (res.code === 200) {
    //       this.appKeysList = res?.data?.list ?? []
    //       this.appKeysNameMap = this.appKeysList.reduce((result, item) => {
    //         result[item.appKey] = item.name
    //         return result
    //       }, {})
    //     }
    //   })
    // },
    async getAppKeys () {
      const res = await commonRequestGet('admin/app-review', this.formData)
      // console.log(res, '1111111')
      this.appKeysList = res?.data ?? []
      // 当排序设置为0时，不在应用下拉框内显示
      this.filteredAppKeysList = this.appKeysList.filter(item => item.rank !== 0)
      this.appKeysList = this.filteredAppKeysList.map(item => {
        return {
          app_key: item.app_id,
          name: item.app_name
        }
      })
      this.appKeysNameMap = this.appKeysList.reduce((result, item) => {
        result[item.app_key] = item.name
        return result
      }, {})
    },
    addUserBlackAndWhiteList () {
      // if (this.subFormData.group === '') {
      //   this.$message.error('请选择商品位置')
      //   return
      // }
      // if (this.subFormData.msorts === '') {
      //   this.$message.error('请输入排序')
      //   return
      // }

      // if (this.subFormData.goodsType === 2 && (this.subFormData.subscriptionType === undefined || !this.subFormData.subscriptionTime || !this.subFormData.subscriptionTimeType || !this.subFormData.content)) {
      //   this.$message.error('请输入完整的订阅商品信息')
      //   return
      // }
      // if (this.subFormData.goodsType === 1 && !this.subFormData.topupCardTime) {
      //   this.$message.error('请输入完整的时长商品信息')
      //   return
      // }
      // if (this.subFormData.goodsType === 7 && !this.subFormData.gold) {
      //   this.$message.error('请输入完整的时长商品信息')
      //   return
      // }
      // if (this.subFormData.goodsType === 1) {
      //   this.subFormData.topupCardTime = this.subFormData.topupCardTime * 3600
      // }
      // if (this.subFormData.goodsType === 2) {
      //   this.subFormData.subscriptionTime = this.subFormData.subscriptionTime * 3600
      // }// const cornerImageKey = this.subFormData.corner_image[0]?.key, cornerImageKey
      // await createGood({ ...this.subFormData, corner_image: this.subFormData.corner_image[0]?.url, first_recharge_offer_corner_image: this.subFormData.first_recharge_offer_corner_image[0]?.url })
      try {
        this.$refs.subFormData.validate(async valid => {
          if (valid) {
            this.addLoading = true
            this.subFormData.subscription_time_type = this.subFormData.time_type
            this.subFormData.first_recharge_offer_subscription_time = this.subFormData.first_recharge_offer_subscription_time * 60 * 60
            // this.subFormData.subscription_time_type = this.subFormData.first_recharge_offer_time_type
            if (this.subFormData.goods_id) {
              // await updateGood(this.subFormData)
              await updateGood({ ...this.subFormData, corner_image: this.imageList[0]?.url || '', first_recharge_offer_corner_image: this.imageListOne[0]?.url || '' })
              this.$message.success('编辑成功')
              console.log(this.subFormData, '编辑成功')
            } else {
              await createGood({ ...this.subFormData, corner_image: this.imageList[0]?.url, first_recharge_offer_corner_image: this.imageListOne[0]?.url })
              this.$message.success('创建成功')
            }
            this.getList()
            this.handleClose()
          }
        })
      } catch (error) {
        this.$message.error(error.response.data.message)
      } finally {
        this.addLoading = false
      }
    },
    relate (goodsId) {
      this.listInfo.loading = true
      // eslint-disable-next-line
      createOpenGood({ 'goods_id': goodsId }).then(() => {
        this.$message.success('关联成功')
      }).catch((error) => {
        this.$message.error(error.response.data.message)
      }).finally(() => {
        this.listInfo.loading = false
      })
    },
    // 编辑按钮方法
    EditItem (row) {
      console.log(row, 'row')

      if (row) {
        if (row.corner_image) {
          this.imageList = [{ url: row.corner_image }]
        }
        if (row.first_recharge_offer_corner_image) {
          this.imageListOne = [{ url: row.first_recharge_offer_corner_image }]
        }
        row.regions = row.regions || {
          region_type: 'all',
          region_code: ''
        }
        console.log(row.first_recharge_offer_subscription_time, '752')
        row.subscription_time_copy = row.subscription_time ? row.subscription_time / 60 / 60 : ''
        this.subFormData = cloneDeep(row)
      }
      this.dialogVisible = true
    },
    changeSubscriptionTime (hour) {
      console.log(hour, 'hour')
      this.subFormData.subscription_time = hour * 60 * 60
    },
    handleClose () {
      // 保存初始值
      this.imageList = []
      this.imageListOne = []
      // 设置为空
      this.subFormData = {
        goods_type: 2,
        app_key: '',
        apple_product_id: '',
        google_product_id: '',
        name: '',
        content: '',
        content_html: '',
        usd_price: '',
        subscription_time_type: 2,
        subscription_time: '',
        subscription_time_copy: '',
        time_type: 2,
        time_number: 1,
        msorts: 0,
        goods_id: '',
        corner_image: '', // 角标
        corner_text: '', // 角标文案
        corner_text_style: {
          color: '', // 文字色
          background_color: '' // 底色
        },
        first_recharge_offer: 0, // 是否首充优惠
        first_recharge_offer_corner_image: '', // 优惠角标图片
        first_recharge_offer_corner_text: '', // 优惠角标文案
        first_recharge_offer_price: 0, // 优惠价格
        first_recharge_offer_time: 0, // 优惠有效期
        first_recharge_offer_time_desc: '', // 优惠有效期文案
        first_recharge_offer_subscription_time: 0, // 优惠时长限制
        first_recharge_offer_time_type: 1, // 优惠时长单位
        first_recharge_offer_content: '', // 优惠状态商品描述
        first_recharge_offer_content_html: '',
        first_recharge_offer_corner_text_style: {
          background_color: '', // 底色
          color: '' // 文字色
        },
        regions: {
          region_type: 'all',
          region_code: ''
        },
        status: 2
      }
      this.dialogVisible = false
      this.$refs.subFormData.resetFields()
      console.log(this.subFormData.first_recharge_offer_subscription_time, '823')
    },
    handleSearch () {
      this.formData.page = 1
      this.getList()
    },
    utcFormatter (cellValue) {
      // eslint-disable-next-line
      return moment(cellValue).utc().format("YYYY-MM-DD HH:mm:ss")
    }
  }
}
</script>
<style>
.btncheck {
  color: rgb(117, 37, 206);
  outline: 0;
  background-color: rgb(243, 234, 252);
}
.elinput .el-input__inner {
  height: 100px;
  width: 478px;
  margin-top: 10px;
}
.overview {
  /* margin-left: 30px; */
  margin-bottom: 10px;
  /* margin-top: 10px; */
}
.checkradio {
  border: 1px solid black;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #8229e5;
}
.image .el-upload--picture-card
{
  width: 80px;
  height: 80px;
}
.image .el-upload-list__item {
  width: 80px;
  height: 80px;
}
.cornerMark {
  display: flex;
}
.corner_text, .background_color ,.color {
  width: 100px;
}
</style>
